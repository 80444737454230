import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPermissions } from '@shared/classes';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ObjectivesImportComponent } from './objectives-import/objectives-import.component';
import { ObjectivesItemComponent } from './objectives-item/objectives-item.component';
import { ObjectivesListComponent } from './objectives-list/objectives-list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'list' },
  {
    path: 'list',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadObjective],
        redirectTo: '/access',
      },
    },
    component: ObjectivesListComponent,
  },
  {
    path: 'import/:typeId',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateObjective],
        redirectTo: '/access',
      },
    },
    component: ObjectivesImportComponent,
  },
  {
    path: 'create',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateObjective],
        redirectTo: '/access',
      },
    },
    component: ObjectivesItemComponent,
  },
  {
    path: 'create/:orgId/:parentId',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateObjective],
        redirectTo: '/access',
      },
    },
    component: ObjectivesItemComponent,
  },
  {
    path: 'edit/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateObjective],
        redirectTo: '/access',
      },
    },
    component: ObjectivesItemComponent,
  },
  {
    path: 'view/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadObjective],
        redirectTo: '/access',
      },
    },
    component: ObjectivesItemComponent,
  },
  {
    path: 'settings',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteObjective],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'bulk-operation',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteObjective],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./bulk-operations/bulk-operations.module').then((m) => m.BulkOperationsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ObjectivesRoutingModule { }
