import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ObjectivesRoutingModule } from './objectives-routing.module';
import { SharedModule } from '@shared/shared.module';
import { ObjectivesItemComponent } from './objectives-item/objectives-item.component';
import { ObjectivesItemFormComponent } from './objectives-item-form/objectives-item-form.component';
import { ObjectivesListComponent } from './objectives-list/objectives-list.component';
import { ObjectivesImportComponent } from './objectives-import/objectives-import.component';

@NgModule({
  declarations: [
    ObjectivesItemComponent,
    ObjectivesItemFormComponent,
    ObjectivesListComponent,
    ObjectivesImportComponent,
  ],
  imports: [CommonModule, ObjectivesRoutingModule, SharedModule],
  exports: [ObjectivesListComponent],
})
export class ObjectivesModule {}
