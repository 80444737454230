import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  ItemBasePage,
  ModuleKeywords,
  ModuleRoutePrefix,
  ObjectiveFieldDto,
  TargetTypeEnum,
  toKebabCase,
} from '@shared/classes';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { ObjectivesDataService } from '../../services/data/objectives-data.service';

@Component({
  selector: 'app-objectives-item',
  templateUrl: './objectives-item.component.html',
  styleUrls: ['./objectives-item.component.scss'],
})
export class ObjectivesItemComponent extends ItemBasePage<ObjectiveFieldDto> implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private requestService: ObjectivesDataService,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private router: Router,
    public localeService: LocaleService
  ) {
    super(
      { moduleKeyword: ModuleKeywords.Objective, routePrefix: ModuleRoutePrefix.Entity },
      router,
      requestService,
      toastService
    );
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.itemId = params['id'];
        if (params['parentId'] && params['orgId']) {
          this.data = { parent: params['parentId'], organization: params['orgId'] };
        }
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate('modules.entity.entity'),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate('modules.entity.objective.objectives'),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId
                  ? this.itemId
                  : this.localeService.translate('general.actions.add') +
                    ' ' +
                    this.localeService.translate('modules.entity.objective.objective'),
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.Objective,
        });
      },
    });
  }

  ngOnInit(): void {}
}
